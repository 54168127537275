<template>
  <div class="lg:text-xl robotic-surgery home relative text-base">
    <a href="#" class="fixed bottom-0 right-0 z-50 mb-4 mr-4 bg-gray-800 border border-white rounded-full">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="48" height="48">
        <path fill="none" d="M0 0h24v24H0z" />
        <path d="M12 10.828l-4.95 4.95-1.414-1.414L12 8l6.364 6.364-1.414 1.414z" fill="rgba(255,255,255,1)" />
      </svg>
    </a>
    <SiteHeaderBH />
    <div class="spacer lg:pt-0 pt-8"></div>
    <section class="lg:pt-24 lg:bg-cover bg-sub-banner relative pt-12 overflow-hidden text-gray-800 bg-center">
      <div
        class="md:w-1/3 lg:w-1/2 bg-surgery lg:opacity-75 absolute top-0 right-0 w-1/2 h-full bg-left bg-cover opacity-50">
      </div>
      <div class="max-w-screen-xl px-4 py-16 mx-auto">
        <h2 class="lg:font-thin lg:text-6xl text-4xl font-light">Bedah Minimal Invasif</h2>
        <p class="lg:text-base text-sm">Beranda > <span class="text-sitePurple font-bold">Bedah Minimal Invasif</span>
        </p>
      </div>
    </section>
    <div class="flex w-full">
      <div class="bg-siteOPurple w-1/3 py-2"></div>
      <div class="bg-sitePPurple w-1/3 py-2"></div>
      <div class="bg-sitePurple w-1/3 py-2"></div>
    </div>
    <section class="text-gray-800">
      <div class="md:pt-16 md:pb-12 lg:pt-20 lg:pb-16 max-w-screen-xl pt-12 pb-8 mx-auto">
        <div class="lg:-mx-4 lg:items-center flex flex-wrap overflow-hidden">
          <div class="w-full px-4 overflow-hidden">
            <p class="mb-4">
              Dengan pengalaman puluhan tahun dalam operasi plastik, Dr Marco selalu mencari metode medis yang lebih
              aman, lebih baru, dan modern untuk membantu pasien mencapai hasil yang optimal.
            </p>
            <p class="mb-8">
              Dr Marco adalah ahli bedah plastik pertama yang mengidentifikasi bahwa teknik endoskopi dapat digunakan
              untuk operasi plastik, dan membuktikan keberhasilannya dengan penyembuhan yang lebih cepat, mengurangi
              jaringan parut, dan hasil yang lebih aman. Sebagai hasil dari prestasi ini, ia dianugerahi oleh 6 lembaga
              yang berbeda dalam proses ini, akhirnya menjadi otoritas di bidang ini.
            </p>
            <a href="tel:+6564648075" class="lg:text-xl text-sitePurple inline-block w-full mb-4 text-lg text-left">Cari
              tahu lebih lanjut tentang operasi plastik invasif minimal dengan membuat janji hari ini. Hubungi Dr Marco
              di +65 6464 8075 sekarang.</a>
          </div>
        </div>
      </div>
    </section>
    <section class="text-gray-800 bg-gray-200">
      <div class="lg:px-24">
        <div class="lg:px-4 md:pt-16 md:pb-12 lg:pt-20 lg:pb-16 pt-12 pb-8 mx-auto">
          <div class="lg:-mx-4 flex flex-wrap overflow-hidden">
            <div class="md:w-1/2 w-full px-4 overflow-hidden">
              <img class="lg:ml-auto mb-4" src="images/image-service-03.jpg">
            </div>
            <div class="md:w-1/2 w-full px-4 overflow-hidden">
              <div class="lg:pl-8">
                <h4 class="lg:text-2xl lg:mb-6 text-sitePurple mb-4 text-xl font-bold uppercase">Bedah Robotik Da Vinci
                </h4>
                <p class="lg:text-xl mb-4 text-lg font-bold">Dr Marco menggunakan sistem bedah da Vinci dan memiliki
                  akreditasi sebagai berikut:</p>
                <ul class="pl-5 mb-4 list-disc">
                  <li class="mb-1">Sertifikat Diploma Bedah Robotik yang diberikan oleh EACCME, Nancy, Prancis</li>
                  <li class="mb-1">Sertifikat Pelatihan Sistem da Vinci sebagai ahli bedah konsol, Korea Yonsei –
                    Shinchon Severance</li>
                  <li>Sertifikat penyelesaian modul Pelatihan Sistem Bedah da Vinci</li>
                </ul>
                <p class="mb-4">
                  Sistem bedah da Vinci memungkinkan Dr Marco untuk memvisualisasikan bidang bedah resolusi tinggi 3D
                  dan memberinya kendali atas lengan robot yang dapat memutar, menekuk, dan beroperasi dengan jangkauan
                  yang lebih luas dibandingkan dengan tangan manusia.
                </p>
                <p class="mb-4">
                  Karena itu, Dr Marco melakukan operasi invasif minimal yang aman dan tepat. Penting untuk dicatat
                  bahwa ini masih operasi Dr Marco, dengan dia menggunakan sistem bedah da Vinci untuk meningkatkan
                  akurasi.
                </p>
                <p class="mb-4">
                  Beberapa keuntungan dari operasi robotik meliputi:
                </p>
                <ul class="pl-5 mb-4 list-disc">
                  <li class="mb-1">Trauma jaringan minimal</li>
                  <li class="mb-1">Tingkat infeksi yang lebih rendah</li>
                  <li class="mb-1">Pemulihan lebih cepat</li>
                  <li>Bekas luka penyembuhan yang lebih kecil dari 0.5 cm hingga 1.5 cm</li>
                </ul>
                <p class="mb-4">
                  Bedah robotik juga telah digunakan dalam bidang medis lain seperti jantung, kolorektal, bedah umum,
                  ginekologi, urologi, bedah kepala & leher.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="text-gray-800">
      <div class="lg:px-24">
        <div class="lg:px-24 md:pt-16 md:pb-12 lg:pt-20 lg:pb-16 pt-12 pb-8 mx-auto">
          <div class="lg:-mx-4 flex flex-wrap overflow-hidden">
            <div class="md:w-1/2 w-full px-4 overflow-hidden">
              <img class="lg:ml-auto mb-4" src="images/image-service-04.jpg">
            </div>
            <div class="md:w-1/2 w-full px-4 overflow-hidden">
              <div class="lg:pl-8">
                <h4 class="lg:text-2xl lg:mb-6 text-sitePurple mb-4 text-xl font-bold uppercase">Bedah Endoskopik Dalam
                  Estetika</h4>
                <p class="lg:text-xl mb-4 text-lg font-bold">Sebagai bagian dari perjalanannya untuk memberikan
                  pengalaman pasien yang positif, Dr Marco menggunakan operasi endoskopi untuk melakukan prosedur
                  estetis utama seperti:</p>
                <ul class="pl-5 mb-4 list-disc">
                  <li class="mb-1">Abdominoplasty/tummy tuck</li>
                  <li class="mb-1">Penganglatan alis</li>
                  <li class="mb-1">Pengangkatan dahi</li>
                  <li class="mb-1">Pembesaran payudara</li>
                  <li class="mb-1">Operasi hidung</li>
                  <li>Perbaikan diastasis rektus</li>
                </ul>
                <p class="mb-4">
                  Di tangan dokter berpengalaman seperti Dr Marco, operasi endoskopi dapat memberi Anda hasil yang luar
                  biasa dengan sedikit ketidaknyamanan. Yakinlah bahwa Dr Marco akan membantu Anda dengan semua
                  pertanyaan, kebutuhan, dan kekhawatiran Anda secara menyeluruh sebelum memulai prosedur apa pun.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <SiteDoctorBH />
    <SiteConsultationBH />
    <SiteFormBH />
    <SiteFooterBH />
  </div>
</template>

<script>
  import SiteHeaderBH from '@/components/SiteHeaderBH.vue'
  import SiteDoctorBH from '@/components/SiteDoctorBH.vue'
  import SiteConsultationBH from '@/components/SiteConsultationBH.vue'
  import SiteFormBH from '@/components/SiteFormBH.vue'
  import SiteFooterBH from '@/components/SiteFooterBH.vue'
  export default {
    components: {
      SiteHeaderBH,
      SiteDoctorBH,
      SiteConsultationBH,
      SiteFormBH,
      SiteFooterBH
    },
    mounted() {  
      document.title = "Dr Marco Faria Correa Plastic Surgery - Operasi Plastik";  
    },
    data() {
      return {
        index: 0,
      }
    }
  }
</script>

<style>
  .bg-sub-banner {
    background-image: url(/images/bg-sub-banner.jpg);
  }
  .bg-surgery {
    background-image: url(/images/bg-surgery.png);
  }
</style>